import produce from 'immer';
import { ValidationError } from '@bridebook/toolbox';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { SupplierActions } from 'lib/supplier/actions-types';
import { Action, IReducersImmer } from 'lib/types';
import { getReducerActions } from 'lib/utils';
import { AuthActionTypes } from '../auth/action-types';
import {
  CreateCustomSupplierErrorAction,
  FetchSupplierCollaborationSuccessAction,
  FetchUserShortlistSuccessAction,
  FetchVenueRecommendedSuppliersSuccessAction,
  OnRemovedSuppliersFromShortlistAction,
  OnSuppliersShortlistedSuccessAction,
  RemoveFromShortlistAction,
  SetCustomSupplierFormFieldAction,
  SetFromBookedSupplierAction,
  SetShortlistedLocationType,
  ShortlistActionTypes,
  ToggleConfirmBookingModalAction,
  ToggleSupplierAddModalAction,
  ToggleSupplierDetailsDrawerAction,
  toggleCustomSupplierFormModalAction,
  toggleGoogleSearchModalAction,
} from './action-types';
import { IShortlistState, ShortlistFilterTypes } from './types';

export const customSupplierForm = {
  disabled: false,
  valid: false,
  useValid: false,
  hideFirstError: false,
  error: false,
  dirty: false,
  fields: {
    type: 'venue',
    slug: 'venue',
    customSupplierType: 'friend',
    countryCode: CountryCodes.GB,
    name: '',
    email: '',
    phone: '',
    website: '',
    address: '',
    postcode: '',
    town: '',
    county: '',
    country: '',
    lat: 0,
    lng: 0,
  },
};

export type CustomSupplierFormType = Omit<typeof customSupplierForm, 'error'> & {
  error: boolean | ValidationError | null;
};

const initialState: IShortlistState = {
  list: {},
  loaded: false,
  initialised: false,
  customSupplier: customSupplierForm,
  customSupplierFormMethod: '',
  addSupplierModalOpened: false,
  addSupplierModal: {
    triggerLocation: null,
  },
  openedSupplierId: null,
  addToBooked: false,
  showSearchGoogleModal: false,
  showCustomSupplierFormModal: false,
  shortlistFilter: ShortlistFilterTypes.ALL,
  collaboration: null,
  collaborationLoaded: false,
  fromBookedSuppliers: false,
  venueRecommendedSuppliers: [],
};

const reducers: IReducersImmer<IShortlistState> = (draft) => ({
  [ShortlistActionTypes.FETCH_USER_SHORTLIST_SUCCESS]: (
    action: FetchUserShortlistSuccessAction,
  ) => {
    const { shortlisted } = action.payload;

    draft.initialised = true;
    draft.loaded = true;
    draft.list = shortlisted;
  },

  [ShortlistActionTypes.ON_REMOVED_SUPPLIERS_FROM_SHORTLIST]: (
    action: OnRemovedSuppliersFromShortlistAction,
  ) => {
    if (action.payload) {
      action.payload.forEach((supplier) => {
        delete draft.list[supplier.id];
      });
    }
  },

  [ShortlistActionTypes.ON_SUPPLIERS_SHORTLISTED_SUCCESS]: (
    action: OnSuppliersShortlistedSuccessAction,
  ) => {
    draft.list = {
      ...draft.list,
      ...action.payload.reduce((acc, supplier) => ({ ...acc, [supplier.id]: supplier }), {}),
    };
  },

  [ShortlistActionTypes.CLEAR_CUSTOM_SUPPLIER_FORM]: () => {
    draft.customSupplier = customSupplierForm;
  },

  [ShortlistActionTypes.SET_CUSTOM_SUPPLIER_FORM_FIELD]: (
    action: SetCustomSupplierFormFieldAction,
  ) => {
    const { name, value } = action.payload;
    // FIXME: switch to `type`, `slug` is for temp backwards compatibility
    if (name === 'type') {
      draft.customSupplier.fields.slug = value;
    }
    // @ts-ignore FIXME
    draft.customSupplier.fields[name] = value;
    draft.customSupplier.error = null;
  },

  [ShortlistActionTypes.REMOVE_FROM_SHORTLIST]: (action: RemoveFromShortlistAction) => {
    if (!action.payload) return;

    delete draft.list[action.payload.id];
  },

  [ShortlistActionTypes.CREATE_CUSTOM_SUPPLIER]: () => {
    draft.customSupplier.disabled = true;
    draft.customSupplier.error = null;
  },

  [ShortlistActionTypes.FETCH_VENUE_RECOMMENDED_SUPPLIERS_SUCCESS]: (
    action: FetchVenueRecommendedSuppliersSuccessAction,
  ) => ({
    ...draft,
    venueRecommendedSuppliers: action.payload,
  }),

  [ShortlistActionTypes.CREATE_CUSTOM_SUPPLIER_SUCCESS]: () => {
    draft.customSupplier.disabled = false;
    draft.customSupplier.dirty = true;
    draft.customSupplier.error = null;
  },
  [ShortlistActionTypes.CREATE_CUSTOM_SUPPLIER_ERROR]: (
    action: CreateCustomSupplierErrorAction,
  ) => {
    draft.customSupplier.disabled = false;
    draft.customSupplier.dirty = true;
    draft.customSupplier.error = action.payload;
  },

  [ShortlistActionTypes.CLEAR_CUSTOM_SUPPLIER_ADDRESS_FORM]: () => {
    draft.customSupplier.fields.address = '';
    draft.customSupplier.fields.town = '';
    draft.customSupplier.fields.country = '';
    draft.customSupplier.fields.postcode = '';
  },

  [ShortlistActionTypes.USE_VALIDATION_ON_TYPE]: () => {
    draft.customSupplier.useValid = true;
    draft.customSupplier.hideFirstError = true;
  },
  [ShortlistActionTypes.CUSTOM_SUPPLIER_FORM_SHOW_NEXT_ERROR]: () => {
    draft.customSupplier.hideFirstError = false;
  },
  [ShortlistActionTypes.CUSTOM_SUPPLIER_FORM_VALID]: () => {
    draft.customSupplier.valid = true;
  },
  [ShortlistActionTypes.HIDE_CUSTOM_SUPPLIER_FORM_ERROR]: () => {
    draft.customSupplier.error = null;
  },
  [ShortlistActionTypes.TOGGLE_SUPPLIER_ADD_MODAL]: (action: ToggleSupplierAddModalAction) => {
    draft.addSupplierModalOpened = action.payload.open;
    draft.addSupplierModal.triggerLocation = action.payload.triggerLocation || null;
    if (!action.payload.open) {
      draft.fromBookedSuppliers = false;
    }
    if (action.payload.addToBooked) {
      draft.addToBooked = true;
    }

    // Clear custom supplier form if it was reopened
    draft.customSupplier = customSupplierForm;

    // Set the category inside the form if the category is passed
    if (action.payload.defaultCategory) {
      draft.customSupplier.fields.type = action.payload.defaultCategory;
      draft.customSupplier.fields.slug = action.payload.defaultCategory;
    }
  },

  [ShortlistActionTypes.TOGGLE_SUPPLIER_DETAILS_DRAWER]: (
    action: ToggleSupplierDetailsDrawerAction,
  ) => {
    const { supplierId } = action.payload;

    draft.openedSupplierId = supplierId;
    if (!supplierId) {
      draft.collaboration = initialState.collaboration;
      draft.collaborationLoaded = false;
    }
  },

  [ShortlistActionTypes.TOGGLE_GOOGLE_SEARCH_MODAL]: (action: toggleGoogleSearchModalAction) => {
    const { show, category } = action.payload;

    // Clear custom supplier form if it was reopened
    draft.customSupplier = customSupplierForm;

    // Show the modal
    draft.showSearchGoogleModal = show;

    // Set the category inside the form and hide the category select if the category is passed
    if (category) {
      draft.customSupplier.fields.type = category;
      draft.customSupplier.fields.slug = category;
    }
  },

  [ShortlistActionTypes.TOGGLE_CUSTOM_SUPPLIER_FORM_MODAL]: (
    action: toggleCustomSupplierFormModalAction,
  ) => {
    const { show, defaultCategory } = action.payload;

    if (defaultCategory) {
      draft.customSupplier.fields.type = defaultCategory;
      draft.customSupplier.fields.slug = defaultCategory;
    }

    draft.showCustomSupplierFormModal = show;
  },

  [AuthActionTypes.SIGN_OUT_COMPLETED]: () => initialState,

  [ShortlistActionTypes.SET_SHORTLISTED_LOCATION]: (action: SetShortlistedLocationType) => {
    draft.shortlistedLocation = action.payload.shortlistedLocation;
  },

  [SupplierActions.VIEWED_SUPPLIER_PROFILE_ANALYTICS]: () => {
    draft.shortlistedLocation = undefined;
  },

  [ShortlistActionTypes.FETCH_SUPPLIER_COLLABORATION_SUCCESS]: (
    action: FetchSupplierCollaborationSuccessAction,
  ) => {
    draft.collaboration = action.payload;
    draft.collaborationLoaded = true;
  },

  [ShortlistActionTypes.TOGGLE_CONFIRM_BOOKING_MODAL]: (
    action: ToggleConfirmBookingModalAction,
  ) => {
    const { supplierToBook, triggerLocation } = action.payload;
    if (supplierToBook) {
      draft.confirmBookingModal = {
        supplierToBook,
        triggerLocation,
      };
    } else {
      delete draft.confirmBookingModal;
    }
  },

  [ShortlistActionTypes.SET_FROM_BOOKED_SUPPLIER]: (action: SetFromBookedSupplierAction) => {
    draft.fromBookedSuppliers = action.payload;
  },
});

const reducersActions = getReducerActions(reducers);

/*
	This is a wrapper function which runs a proper reducer from the object above.
*/
const reducer = (state: IShortlistState = initialState, action: Action): IShortlistState => {
  if (!reducersActions[action.type]) {
    return state;
  }

  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
